import React from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import { FiChevronDown } from 'react-icons/fi'
import Button from '../common/Button'
import storage from '../../../lib/storage'
import mixpanel from 'mixpanel-browser'

class BrandTabsContainer extends React.Component {
  render() {
    const { active, highlights, surveys } = this.props
    const surveyId = surveys[0] && surveys[0]._id

    return (
      <div className="marketingTabsContainer is-hidden-mobile invert-colors small">
        <div className="buttons-container"></div>
        <div className="links-container">
          <Link
            onClick={() => {
              mixpanel.track('ri:nav-sub', {
                location: 'Brand Tab',
                action: 'Navigate: Track',
              })
            }}
            to="/marketplace/brand/tracking"
            id="trackingTab"
            className={`link ${active === 'tracking' ? 'active' : ''}`}
          >
            Track
          </Link>
          <Link
            onClick={() => {
              mixpanel.track('ri:nav-sub', {
                location: 'Brand Tab',
                action: 'Navigate: Competitors',
              })
            }}
            to="/marketplace/brand/competitors"
            id="competitorsTab"
            className={`link ${active === 'competitors' ? 'active' : ''}`}
          >
            Competitors
          </Link>
          <Link
            onClick={() => {
              mixpanel.track('ri:nav-sub', {
                location: 'Brand Tab',
                action: 'Navigate: Sentiment',
              })
            }}
            to="/marketplace/brand/sentiment"
            id="playbooksTab"
            className={`link ${active === 'sentiment' ? 'active' : ''}`}
          >
            Sentiment
          </Link>
          <p
            onClick={() => {
              mixpanel.track('ri:nav-sub', {
                location: 'Brand Tab',
                action: 'Navigate: Predict',
                notes: 'Disabled Button',
              })
            }}
            id="researchTab"
            className={`link ${active === 'research' ? 'active' : ''} disabled`}
          >
            Predict
          </p>
        </div>
        <div className="buttons-container">
          <Button
            text="Methodology"
            className="is-blue-lines is-light-font padded-small"
            width={120}
            onClick={() => {
              mixpanel.track('ri:nav-sub', {
                location: 'Brand Tab',
                action: 'Navigate: Methodology',
              })

              window.open(
                `https://insights.collegepulse.com/vault/survey/${surveyId}?token=${storage.get(
                  'token'
                )}&highlights`,
                '_blank'
              )
            }}
          />
          <div className="dropdown is-right is-hoverable">
            <div className="dropdown-trigger">
              {
                // <Button
                //   text="Highlights"
                //   className="is-blue-lines is-light-font padded-small"
                //   width={120}
                //   onClick={() =>
                //     window.open(
                //       `https://insights.collegepulse.com/vault/question/5db86e7b3c4a5c000ed86202/highlight/0${storage.get(
                //         "token"
                //       )}`,
                //       "_blank"
                //     )
                //   }
                // />
              }
              <button
                onClick={() => {
                  mixpanel.track('ri:nav-sub', {
                    location: 'Brand Tab',
                    action: 'Action: Highlights Dropdown',
                  })
                }}
                className="button productButton is-blue-lines is-light-font padded-small unset-height"
                aria-haspopup="true"
                aria-controls="dropdown-menu4"
                style={{ width: 120 }}
              >
                <span>Highlights</span>
                <FiChevronDown />
              </button>
            </div>
            <div
              className="dropdown-menu"
              id="dropdown-menu4"
              role="menu"
              style={{ width: '24rem' }}
            >
              <div className="dropdown-content">
                {surveys.map(survey => (
                  <div className="dropdown-item" key={survey._id}>
                    <a
                      onClick={() => {
                        mixpanel.track('ri:nav-sub', {
                          location: 'Brand Tab : Highlights',
                          action: 'Navigate: Survey',
                          surveyId: survey._id,
                        })
                      }}
                      href={`https://insights.collegepulse.com/vault/survey/${
                        survey._id
                      }?token=${storage.get('token')}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {survey.title}
                    </a>
                    <hr className="dropdown-divider" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    surveyId: state.brandReports.surveyId,
    highlights: state.brandReports.highlights,
    surveys: state.brandReports.surveys,
  }),
  {}
)(BrandTabsContainer)
