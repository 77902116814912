import React from 'react'
import { connect } from 'react-redux'

import Layout from '../../../../components/Layout'
import BrandTabs from '../../../../components/marketplace/brand/BrandTabs'

class Predictors extends React.Component {
  render() {
    return (
      <Layout noFooter active="brand" requireAuth>
        <BrandTabs active="predictors" />
        <iframe
          title="brand_infogram"
          src={this.props.brandTrackerLink}
          className="full-page-iframe"
        />
      </Layout>
    )
  }
}

export default connect(
  state => ({
    brandTrackerLink: state.brandReports.predictInfogram,
  }),
  {}
)(Predictors)
